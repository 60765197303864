var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"themev2"},[_c('br'),_c('div',{staticClass:"logo-centered"}),(!_vm.form.token || !_vm.form.email)?_c('div',{staticClass:"w560"},[(!_vm.resetRequested)?_c('div',{staticClass:"w300"},[_c('h1',[_vm._v("Reset password")]),_c('p',[_vm._v("Enter the email you registered your account with and we will send you a link which will let you create a new password")])]):_vm._e(),(!_vm.resetRequested)?_c('ValidationObserver',{staticClass:"w300",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"requestForm",class:{disabled: _vm.spinner},attrs:{"method":"POST","spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitRequest)}}},[_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"email","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"emaillabel",attrs:{"for":"email"}},[_vm._v("EMAIL YOU REGISTERED WITH")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],class:classes,attrs:{"name":"email","type":"email","novalidate":"","disabled":_vm.spinner},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"full",class:{disabled: _vm.spinner},attrs:{"type":"submit","disabled":_vm.spinner}},[_vm._v("Reset my password")])],1)]}}],null,false,3000443101)}):_vm._e(),(_vm.resetRequested)?_c('div',{staticClass:"w300"},[_vm._m(0),_c('p',[_vm._v("We've emailed you a link to "),_c('b',[_vm._v(_vm._s(_vm.form.email))]),_vm._v(" that you can use to reset your password and create a new one.")]),_c('button',{staticClass:"full",on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Back to login screen")])]):_vm._e(),(_vm.spinner)?_c('span',{staticClass:"spinner"}):_vm._e(),(_vm.error)?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1):_vm._e(),(_vm.form.token && _vm.form.email)?_c('div',{staticClass:"w560"},[_vm._m(1),_c('ValidationObserver',{staticClass:"w300",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"resetForm",class:{disabled: _vm.$store.getters.loading},attrs:{"method":"POST","spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmitReset)}}},[_c('ValidationProvider',{ref:"password",staticClass:"inputrow",attrs:{"mode":"eager","name":"password","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("New password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],class:classes,attrs:{"name":"password","type":"password","novalidate":"","placeholder":"","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"mode":"eager","name":"password2","rules":"required|confirmed:password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password2"}},[_vm._v("Repeat new password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password2),expression:"form.password2"}],class:classes,attrs:{"name":"password2","type":"password","novalidate":"","placeholder":"","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.password2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password2", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"insead large",class:{disabled: _vm.spinner},attrs:{"type":"submit","disabled":_vm.spinner}},[_vm._v("Set password")]),(_vm.spinner)?_c('span',{staticClass:"spinner"}):_vm._e(),(_vm.$store.state.auth.status == 'pwd_reset_error')?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.$store.state.auth.error))]):_vm._e()],1)]}}],null,false,3946684368)})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',[_c('span',{staticClass:"checkmark"}),_vm._v("Check your email")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w300"},[_c('h1',[_vm._v("Choose new password")]),_c('p',[_vm._v("Password must be minimum 8 characters and contain at least 1 number.")])])}]

export { render, staticRenderFns }